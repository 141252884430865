<template>
  <div id="app">
    <div id="shell">
      <Header></Header>
      <div id="body">
        <router-view :key="$route.fullPath" />
      </div>
      <!-- end of body -->
      <Footer></Footer>
      <FooterChat v-if="showChatBoot"></FooterChat>
      <LoginRegister></LoginRegister>
      <ForgotPassword></ForgotPassword>
    </div>
    <!-- end of shell -->
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import FooterChat from "@/components/FooterChat.vue";
import LoginRegister from "@/components/PopUp/LoginRegister.vue";
import ForgotPassword from "@/components/PopUp/ForgotPassword.vue";

export default {
  name: "App",
  components: {
    Header,
    Footer,
    FooterChat,
    LoginRegister,
    ForgotPassword
  },
  computed: {
    showChatBoot() {
      const path = this.$route.path;
      return !path.includes("account");
    }
  }
};
</script>
