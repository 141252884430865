import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "root",
    redirect: "/home"
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("../views/Home.vue")
  },
  {
    path: "/term-and-condition",
    name: "TermAndCondition",
    component: () => import("../views/Term&Condition.vue")
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () => import("../views/PrivacyPolicy.vue")
  },
  {
    path: "/find-property",
    name: "FindProperty",
    component: () => import("../views/find-property/FindProperty.vue")
  },
  {
    path: "/property/:id",
    name: "DetailProperty",
    component: () => import("../views/find-property/DetailProperty.vue")
  },
  {
    path: "/property/:id/booking/:transactionType",
    name: "BookingProperty",
    component: () => import("../views/find-property/BookingProperty.vue")
  },
  {
    path: "/property/:id/booking/:transactionType/complete",
    name: "BookingCompleteProperty",
    component: () =>
      import("../views/find-property/BookingCompleteProperty.vue")
  },
  {
    path: "/market-my-properties",
    name: "MarketMyProperties",
    component: () => import("../views/MarketMyProperties.vue")
  },
  {
    path: "/about-us",
    name: "AboutUs",
    component: () => import("../views/about/AboutUs.vue")
  },
  {
    path: "/about-us/who-are-we",
    name: "WhoAreWe",
    component: () => import("../views/about/WhoAreWe.vue")
  },
  {
    path: "/about-us/why-rubii",
    name: "WhyRubii",
    component: () => import("../views/about/WhyRubii.vue")
  },
  {
    path: "/about-us/who-should-use-rubii",
    name: "ShowShouldUseRubii",
    component: () => import("../views/about/WhoShouldUseRubii.vue")
  },
  {
    path: "/about-us/rent-to-own",
    name: "RentToOwn",
    component: () => import("../views/about/RentToOwn.vue")
  },
  {
    path: "/about-us/non-aggressive-selling",
    name: "NonAggressiveSelling",
    component: () => import("../views/about/NonAggressiveSelling.vue")
  },
  {
    path: "/about-us/commissions",
    name: "Commissions",
    component: () => import("../views/about/Commissions.vue")
  },
  {
    path: "/about-us/visi-misi",
    name: "VisiMisi",
    component: () => import("../views/about/VisiMisi.vue")
  },
  {
    path: "/about-us/article",
    name: "Article",
    component: () => import("../views/about/Article.vue")
  },
  {
    path: "/about-us/article/:id",
    name: "ArticleDetail",
    component: () => import("../views/about/ArticleDetail.vue")
  },
  {
    path: "/account/setting",
    name: "AccountSetting",
    component: () => import("../views/account/Setting"),
    meta: {
      authRequired: true
    }
  },
  {
    path: "/account/transaction",
    name: "AccountTransaction",
    component: () => {
      if (process.env.VUE_APP_ENV == "development") {
        return import("../views/account/Transaction");
      } else {
        return import("../views/PageNotFound.vue");
      }
    },
    meta: {
      authRequired: true
    }
  },
  {
    path: "/account/transaction/:id",
    name: "AccountTransactionDetail",
    component: () => import("../views/account/TransactionDetail"),
    meta: {
      authRequired: true
    }
  },
  {
    path: "/account/my-properties",
    name: "AccountMyProperties",
    component: () => {
      if (process.env.VUE_APP_ENV == "development") {
        return import("../views/account/MyProperties");
      } else {
        return import("../views/PageNotFound.vue");
      }
    },
    meta: {
      authRequired: true
    }
  },
  {
    path: "/account/my-properties/:id",
    name: "AccountMyPropertiesDetail",
    component: () => import("../views/account/MyPropertiesDetail"),
    meta: {
      authRequired: true
    }
  },
  {
    path: "/account/request-property",
    name: "AccountRequestProperty",
    component: () => {
      if (process.env.VUE_APP_ENV == "development") {
        return import("../views/account/RequestProperty");
      } else {
        return import("../views/PageNotFound.vue");
      }
    },
    meta: {
      authRequired: true
    }
  },
  {
    path: "/account/member-point",
    name: "MemberPoint",
    component: () => {
      if (process.env.VUE_APP_ENV == "development") {
        return import("../views/account/MemberPoint");
      } else {
        return import("../views/PageNotFound.vue");
      }
    },
    meta: {
      authRequired: true
    }
  },
  {
    path: "/account/member-point/detail",
    name: "MemberPointDetail",
    component: () => {
      if (process.env.VUE_APP_ENV == "development") {
        return import("../views/account/MemberPointDetail");
      } else {
        return import("../views/PageNotFound.vue");
      }
    },
    meta: {
      authRequired: true
    }
  },
  {
    path: "/account/saved-properties",
    name: "SavedProperties",
    component: () => import("../views/account/SavedProperties"),
    meta: {
      authRequired: true
    }
  },
  {
    path: "/account/message",
    name: "Message",
    component: () => {
      if (process.env.VUE_APP_ENV == "development") {
        return import("../views/account/Message")
      } else {
        return import("../views/PageNotFound.vue");
      }
    },
    meta: {
      authRequired: true
    }
  },
  {
    path: "/mortgage",
    name: "Mortgage",
    component: () => import("../views/Mortgage.vue")
  },
  {
    path: "/construction",
    name: "Construction",
    component: () => import("../views/Construction.vue")
  },
  {
    path: "/events",
    name: "Events",
    component: () => import("../views/event/EventList.vue")
  },
  {
    path: "/faq",
    name: "Faq",
    component: () => import("../views/Faq.vue")
  },
  {
    path: "/contact-us",
    name: "ContactUs",
    component: () => import("../views/ContactUs.vue")
  },
  {
    path: "/reset-password/:token",
    name: "ResetPassword",
    component: () => import("../views/ResetPassword.vue")
  },
  {
    path: "/mobile/services",
    name: "mService",
    component: () => {
      if (process.env.VUE_APP_ENV == "development") {
        return import("../views/mobile/Services.vue");
      } else {
        return import("../views/PageNotFound.vue");
      }
    }
  },
  {
    path: "/mobile/search",
    name: "mSearch",
    component: () => {
      if (process.env.VUE_APP_ENV == "development") {
        return import("../views/mobile/Search.vue");
      } else {
        return import("../views/PageNotFound.vue");
      }
    }
  },
  {
    path: "/mobile/account",
    name: "mAccount",
    component: () => import("../views/mobile/Account.vue")
  },
  {
    path: "/mobile/login",
    name: "mLogin",
    component: () => import("../views/mobile/Login.vue")
  },
  {
    path: "/mobile/register",
    name: "mRegister",
    component: () => import("../views/mobile/Register.vue")
  },
  {
    path: "/mobile/forgot-password",
    name: "mForgotPassword",
    component: () => import("../views/mobile/ForgotPassword.vue")
  },
  {
    path: "*",
    name: "PageNotFound",
    component: () => import("../views/PageNotFound.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  linkActiveClass: "active",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.authRequired)) {
    if (store.state.userdata == null) {
      next("/home#login");
    }
  }
  next();
});

export default router;
