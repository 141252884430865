<template>
  <footer id="bottom">
    <div class="footnav">
      <div class="wrapper">
        <div class="rowflex">
          <template v-for="(link, i) in links">
            <div class="item" :key="i">
              <h3>{{ link.title }}</h3>
              <ul>
                <template v-for="(content, j) in link.contents">
                  <li :key="j">
                    <router-link :to="`/${content.link}`" target="_blank">
                      {{ content.title }}
                    </router-link>
                  </li>
                </template>
              </ul>
            </div>
          </template>
          <!-- end of item -->
        </div>
        <!-- end of rowflex -->
      </div>
      <!-- end of wrapper -->
    </div>
    <!-- end of footnav -->

    <div class="footbar">
      <div class="wrapper">
        <div class="topline clearfix">
          <div class="logo">
            <router-link to="/home">
              <img src="/img/logo-footer.svg" width="98" />
            </router-link>
          </div>
          <div class="footer-menu">
            <ul>
              <li>
                <router-link to="/about-us">
                  {{ $t("footer.AboutUs") }}
                </router-link>
              </li>
              <li>
                <router-link to="/find-property">
                  {{ $t("footer.FindProperties") }}
                </router-link>
              </li>
              <li>
                <router-link to="/market-my-properties">
                  {{ $t("footer.MarketMyProperties") }}
                </router-link>
              </li>
              <li>
                <a href="#">
                  {{ $t("footer.OtherServices") }}
                </a>
              </li>
              <li>
                <router-link to="/events">
                  {{ $t("footer.Event") }}
                </router-link>
              </li>
              <li>
                <router-link to="/contact-us">
                  {{ $t("footer.ContactUs") }}
                </router-link>
              </li>
              <li>
                <router-link to="/faq">
                  {{ $t("footer.FAQ") }}
                </router-link>
              </li>
            </ul>
          </div>
          <!-- end of footer menu -->
        </div>
        <!-- end of topline -->
        <div class="botline clearfix">
          <p>© 2021 RUBII. All Rights Reserved</p>
          <ul>
            <li>
              <router-link to="/term-and-condition">
                {{ $t("footer.TermCondition") }}
              </router-link>
            </li>
            <li>
              <router-link to="/privacy-policy">
                {{ $t("footer.PrivacyPolicy") }}
              </router-link>
            </li>
          </ul>
          <div class="store">
            <a href="#" class="cvr-bg as" title="App Store"></a>
            <a href="#" class="cvr-bg ps" title="Play Store"></a>
          </div>
          <!-- end of store -->
          <div class="social">
            <a href="#" class="cvr-bg in" title="Linkedin"></a>
            <a href="#" class="cvr-bg fb" title="Facebook"></a>
            <a href="#" class="cvr-bg ig" title="Instagram"></a>
            <a href="#" class="cvr-bg yb" title="YouTube"></a>
          </div>
          <!-- end of social -->
        </div>
        <!-- end of botline -->
      </div>
      <!-- end of wrapper -->
    </div>
    <!-- end of footbar -->
  </footer>
  <!-- end of bottom -->
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      links: [],
      types: [
        "Rent-to-Own Apartment",
        "Rent Apartment",
        "Buy Apartemen",
        "Rent-to-Own House",
        "Rent House",
        "Buy House"
      ],
      cities: ["Surabaya", "Jogja", "Jakarta", "Bandung"]
    };
  },
  mounted() {
    this.getContent();
  },
  methods: {
    getContent() {
      this.$axios
        .get(`/pages/footer`, {
          params: {
            lang: this.$store.state.locale.lang
          }
        })
        .then(response => {
          this.links = response.data.data;
        });
    }
  }
};
</script>
