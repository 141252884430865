import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userdata: null,
    showCartRightBar: false,
    surveyHistories: [],
    modalDownloadApp: false,
    recentProduct: null,
    locale: null,
    booking: null,
    priceDetail: null,
    checkout: null
  },
  mutations: {
    SET_USERDATA(state, payload) {
      state.userdata = payload;
    },
    SET_LOCALE(state, payload) {
      state.locale = payload;
    },
    SHOW_CART(state, payload) {
      state.showCartRightBar = payload;
    },
    SAVE_SURVEY(state, payload) {
      state.surveyHistories = payload;
    },
    SHOW_MODAL_DOWNLOAD(state, show) {
      state.modalDownloadApp = show;
    },
    SET_RECENT_PRODUCT(state, productId) {
      state.recentProduct = productId;
    },
    SET_BOOKING(state, payload) {
      state.booking = payload;
    },
    SET_PRICE_DETAIL(state, payload) {
      state.priceDetail = payload;
    },
    SET_CHECKOUT(state, payload) {
      state.checkout = payload;
    }
  },
  actions: {},
  modules: {}
});
