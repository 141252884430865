import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./helpers/filter";
import "./helpers/mixin";
// import VueSocketIO from "vue-socket.io-params";

Vue.config.productionTip = false;
import "@/assets/app.scss";

import cookie from "js-cookie";
// setup locale
if (cookie.getJSON("locale") === undefined) {
  const defaultLocale = {
    lang: "en"
  };
  store.commit("SET_LOCALE", defaultLocale);
  cookie.set("locale", defaultLocale, { expires: 1 });
} else {
  const defaultLocale = cookie.getJSON("locale");
  store.commit("SET_LOCALE", defaultLocale);
}

// setup axios
import axios from "axios";
Vue.prototype.$axios = axios.create({
  baseURL: `${process.env.VUE_APP_BASE_URL_API}`,
  headers: {
    Accept: "application/json",
    "X-lang": store.state.locale.lang.toUpperCase()
  }
});
// set default query params
// Vue.prototype.$axios.defaults.params = { lang: store.state.locale.lang };
// set authorization headers
if (cookie.getJSON("rubii") !== undefined) {
  let auth = cookie.getJSON("rubii");
  store.commit("SET_USERDATA", auth);
  Vue.prototype.$axios.defaults.headers.common["Authorization"] =
    "Bearer " + auth.accessToken;

  // Vue.use(
  //   new VueSocketIO({
  //     debug: true,
  //     connection: process.env.VUE_APP_BASE_URL_API,
  //     params: {
  //       query: `token=${auth.accessToken}`,
  //       type: ["websocket"]
  //     },
  //     options: {
  //       path: "/"
  //     }
  //   })
  // );
}

import VueChatScroll from "vue-chat-scroll";
Vue.use(VueChatScroll);

import PerfectScrollbar from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
Vue.use(PerfectScrollbar);

import VeeValidate from "vee-validate";
Vue.use(VeeValidate);

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";
// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
Vue.use(Loading);

import VueSweetalert2 from "vue-sweetalert2";
Vue.use(VueSweetalert2, {
  confirmButtonColor: "#EB8023"
});

import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
Vue.use(VueAwesomeSwiper /* { default global options } */);

import VueMeta from "vue-meta";
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
Vue.use(DatePicker);

import { Fancybox } from "@fancyapps/ui/src/Fancybox/Fancybox.js";
import "@fancyapps/ui/dist/fancybox.css";
Fancybox.bind("[data-fancybox]", {
  closeExisting: true,
  animationEffect: "fade",
  animationDuration: 500,
  transitionEffect: "zoom-in-out",
  clickOutside: "close",
  clickSlide: "close",
  touch: false,
  autoFocus: true
});

// setup i18n
import VueI18n from "vue-i18n";
Vue.use(VueI18n);
import english from "./i18n/en";
import indonesia from "./i18n/id";
const i18n = new VueI18n({
  locale: store.state.locale.lang, // set locale
  messages: {
    en: english,
    id: indonesia
  } // set locale messages
});

import VueSocialSharing from "vue-social-sharing";
Vue.use(VueSocialSharing);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
