<template>
  <header id="top">
    <div class="wrapper rowflex">
      <div class="logo">
        <router-link to="/home">
          <img src="/img/logo.png" width="102" />
        </router-link>
      </div>
      <div class="mainmenu">
        <div class="mobile-trigger">
          <a href="#" class="menu-mobile" ref="hamburgerMenu">
            <span class="menu-bar"><b></b></span>
          </a>
        </div>
        <div class="menu-holder">
          <ul>
            <li :class="{ current: isAboutPage }" @click="closeHamburger">
              <router-link to="/about-us">
                {{ $t("header.AboutUs") }}
              </router-link>
            </li>
            <li :class="{ current: isPropertyPage }" @click="closeHamburger">
              <router-link to="/find-property">
                {{ $t("header.FindProperties") }}
              </router-link>
            </li>
            <li
              :class="{ current: $route.name == 'MarketMyProperties' }"
              @click="closeHamburger"
            >
              <router-link to="/market-my-properties">
                {{ $t("header.MarketMyProperties") }}
              </router-link>
            </li>
            <li class="has-children" :class="{ current: isServicePage }">
              <a href="#">{{ $t("header.OtherServices") }}</a>
              <b></b>
              <ul>
                <li @click="closeHamburger">
                  <router-link to="/construction">
                    {{ $t("header.ConstructionService") }}
                  </router-link>
                </li>
                <li @click="closeHamburger">
                  <router-link to="/mortgage">
                    {{ $t("header.MortgageService") }}
                  </router-link>
                </li>
              </ul>
            </li>
            <li
              :class="{ current: $route.name == 'Events' }"
              @click="closeHamburger"
            >
              <router-link to="/events">{{ $t("header.Event") }}</router-link>
            </li>
            <li
              :class="{ current: $route.name == 'ContactUs' }"
              @click="closeHamburger"
            >
              <router-link to="/contact-us">
                {{ $t("header.ContactUs") }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <!-- end of main menu -->
      <div class="util">
        <div class="lang">
          <span class="cvr-bg-af">
            <template v-if="$store.state.locale.lang == 'en'">EN</template>
            <template v-else-if="$store.state.locale.lang == 'id'">ID</template>
          </span>
          <ul>
            <li>
              <a href="#" @click="changeLocale('en')">EN</a>
            </li>
            <li>
              <a href="#" @click="changeLocale('id')">ID</a>
            </li>
          </ul>
        </div>
        <!-- end of lang -->
        <template v-if="$store.state.userdata">
          <div class="profile">
            <router-link to="/account/message" class="cvr-bg ic-chat">
              <!-- <b>8</b> -->
            </router-link>
            <div class="account">
              <a href="#" class="cvr-bg ic-bell">
                <!-- <b>8</b> -->
              </a>
              <router-link to="/account/setting" class="trigger-account">
                <figure v-if="$store.state.userdata.account.user.avatar">
                  <img
                    :src="$store.state.userdata.account.user.avatar"
                    width="40"
                  />
                </figure>
                <figure v-else>
                  <img src="/img/dummy/default-profile.png" width="40" />
                </figure>
              </router-link>
            </div>
          </div>
        </template>
        <template v-else>
          <a
            href="javascript:"
            data-src="#login-register"
            class="button"
            data-fancybox
            ref="logreg"
          >
            {{ $t("header.LogReg") }}
          </a>
        </template>
      </div>
      <!-- end of util -->
    </div>
    <!-- end of wrapper -->
  </header>
  <!-- end of top -->
</template>

<script>
import cookie from "js-cookie";
export default {
  name: "Header",
  data() {
    return {
      isAboutPage: false,
      isServicePage: false,
      isPropertyPage: false
    };
  },
  watch: {
    $route(to) {
      if (
        to.name == "AboutUs" ||
        to.name == "AboutUs" ||
        to.name == "WhoAreWe" ||
        to.name == "WhyRubii" ||
        to.name == "ShowShouldUseRubii" ||
        to.name == "RentToOwn" ||
        to.name == "NonAggressiveSelling" ||
        to.name == "Commissions" ||
        to.name == "VisiMisi" ||
        to.name == "Article" ||
        to.name == "ArticleDetail"
      ) {
        this.isAboutPage = true;
      } else {
        this.isAboutPage = false;
      }

      if (to.name == "Construction" || to.name == "Mortgage") {
        this.isServicePage = true;
      } else {
        this.isServicePage = false;
      }

      if (
        to.name == "FindProperty" ||
        to.name == "FindPropertyByType" ||
        to.name == "DetailProperty"
      ) {
        this.isPropertyPage = true;
      } else {
        this.isPropertyPage = false;
      }

      if (to.hash === "#login") {
        this.$refs.logreg.click();
      }
    }
  },
  methods: {
    changeLocale(language) {
      const defaultLocale = {
        lang: language
      };
      cookie.set("locale", defaultLocale, { expires: 1 });
      window.location.reload();
    },
    closeHamburger() {
      if (document.body.classList.contains("open-menu")) {
        this.$refs.hamburgerMenu.click();
      }
    }
  }
};
</script>
