import Vue from "vue";
import moment from "moment";
import slugify from "slugify";

Vue.mixin({
  data() {
    return {
      base_url: process.env.VUE_APP_BASE_URL
    };
  },
  computed: {
    socket_url() {
      const base_url = process.env.VUE_APP_BASE_URL_API;
      return base_url.replace("https", "wss");
    }
  },
  methods: {
    formatDateForParam(value, sourceFormat) {
      return moment(value, sourceFormat).format("YYYY/MM/DD");
    },
    slug(string) {
      return slugify(string, {
        lower: true
      });
    },
    convertToRupiah: (angka, prefix = true) => {
      var rupiah = "";
      var angkarev = angka
        .toString()
        .split("")
        .reverse()
        .join("");
      for (var i = 0; i < angkarev.length; i++)
        if (i % 3 == 0) rupiah += angkarev.substr(i, 3) + ".";
      return prefix
        ? "Rp. "
        : "" +
            rupiah
              .split("", rupiah.length - 1)
              .reverse()
              .join("");
    },
    convertToAngka: rupiah => {
      if (rupiah == "") {
        return 0;
      } else {
        return parseInt(rupiah.replace(/,.*|[^0-9]/g, ""), 10);
      }
    },
    propertyTypeTranslate(type) {
      if (this.$store.state.locale.lang == "end") {
        return type.name_en;
      } else {
        return type.name_id;
      }
    },
    showBedAndBath(propertyType) {
      if ([1, 2, 7, 8, 9].includes(propertyType)) {
        return true;
      } else {
        return false;
      }
    },
    checkType(urlFile) {
      const types = new Map([
        ["jpg", "img"],
        ["png", "img"],
        ["jpeg", "img"],
        ["gif", "img"],
        ["mp4", "video"],
        ["3gp", "video"]
      ]);
      const pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i" // fragment locator
      );
      if (pattern.test(urlFile)) {
        const url = new URL(urlFile);
        const extension = url.pathname.split(".")[1];
        return types.get(extension);
      } else {
        return "img";
      }
    }
  }
});

export default Vue;
