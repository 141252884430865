<template>
  <div class="footer-chat" id="footer-chat">
    <div class="box">
      <h2>Directory <a href="#" class="cvr-bg"></a></h2>
      <div class="chat" ref="chat">
        <perfect-scrollbar id="chatbot" class="chat-container">
          <template v-for="(chat, index) in chats">
            <div :key="index" style="margin-top: 10px">
              <div class="list">
                <div
                  class="item"
                  v-for="(question, qindex) in chat.question"
                  :key="qindex"
                >
                  <p>{{ question.message }}</p>
                </div>
              </div>
              <div class="tags">
                <template v-for="(answer, aindex) in chat.answer">
                  <a href="#" :key="aindex" @click="chooseAnswer(answer)">
                    {{ answer.message }}
                  </a>
                </template>
              </div>
            </div>
          </template>
        </perfect-scrollbar>
      </div>
    </div>
    <!-- end f box -->
    <!-- <a href="#" class="cvr-bg trigger-chat"></a> -->
    <a
      href="https://wa.me/+6287708779768"
      target="_blank"
      class="cvr-bg trigger-chat"
    ></a>
    <button
      ref="chatBot"
      type="button"
      class="button vhidden"
      data-src="#popup-chat"
      data-fancybox
    >
      Chat to Rubii Agent
    </button>
    <div id="popup-chat" class="popup-addprop" style="display: none;">
      <h3 v-if="targetChat === 'CSA'">
        Which Location Are You Interested In?
      </h3>
      <h3 v-if="targetChat === 'LO'">
        Where Is Your Property’s Location?
      </h3>
      <div class="fgroup" v-if="targetChat === 'CSA'">
        <fieldset>
          <select v-model="baseCity">
            <option></option>
            <option
              v-for="(city, index) in CSAcities"
              :key="index"
              :value="city"
            >
              {{ city }}
            </option>
            <option :value="0">Others</option>
          </select>
        </fieldset>
      </div>
      <div class="fgroup" v-if="targetChat === 'LO'">
        <fieldset>
          <select v-model="baseCity">
            <option></option>
            <option
              v-for="(city, index) in LOcities"
              :key="index"
              :value="city"
            >
              {{ city }}
            </option>
            <option :value="0">Others</option>
          </select>
        </fieldset>
      </div>
      <div class="toright">
        <template v-if="$store.state.userdata">
          <button
            type="button"
            class="button close"
            :disabled="baseCity === null"
            @click="startChat()"
          >
            Start Chat With {{ targetChat }}
          </button>
        </template>
        <template v-else>
          <button
            type="button"
            class="button"
            data-src="#login-register"
            data-fancybox
          >
            Start Chat With {{ targetChat }}
          </button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterChat",
  data() {
    return {
      chats: [],
      slugAction: null,
      baseCity: null,
      targetChat: null,
      CSAcities: [],
      LOcities: []
    };
  },
  mounted() {
    this.initChat("init");
  },
  methods: {
    initChat(payload) {
      this.$axios
        .post(
          `/chat-bot`,
          {
            slug: payload
          },
          {
            params: {
              lang: this.$store.state.locale.lang
            }
          }
        )
        .then(response => {
          this.chats.push(response.data.data);
          const objDiv = document.getElementById("chatbot");
          this.$nextTick(() => {
            objDiv.scrollTop = objDiv.scrollHeight;
          });
        });
    },
    chooseAnswer(answer) {
      console.log(answer);
      if (answer.redirect !== undefined) {
        window.location.href = `/${answer.redirect}`;
      } else {
        if (answer.slug.includes("more")) {
          // talk representative rubii agent
          if (
            answer.slug === "more-retring-or-buying" ||
            answer.slug === "more-rent-or-buy" ||
            answer.slug === "more-construction-or-mortgage"
          ) {
            // chat ke CSA
            this.targetChat = "CSA";
            this.getAvailableCities();
            this.$refs.chatBot.click();
          } else if (
            answer.slug === "more-renting-or-selling" ||
            answer.slug === "more-list-my-property"
          ) {
            // chat ke LO
            this.targetChat = "LO";
            this.getAvailableCities();
            this.$refs.chatBot.click();
          } else {
            this.initChat(answer.slug);
          }
        } else if (!answer.slug.includes("talk")) {
          this.initChat(answer.slug);
        } else {
          if (answer.slug === "market-my-properties-talk-agent") {
            // chat ke LO
            this.targetChat = "LO";
          } else {
            // chat ke CSA
            this.targetChat = "CSA";
          }
          this.getAvailableCities();
          this.$refs.chatBot.click();
        }
      }
    },
    getAvailableCities() {
      this.$axios
        .get(`/misc/available-cities`, {
          params: {
            role: this.targetChat
          }
        })
        .then(response => {
          if (this.targetChat === "CSA") {
            this.CSAcities = response.data.data;
          } else {
            this.LOcities = response.data.data;
          }
        });
    },
    startChat() {
      const url =
        this.targetChat === "CSA"
          ? "/conversations/init-csa"
          : "/conversations/init-lo";
      this.$axios
        .post(url, {
          baseCity: this.baseCity === 0 ? null : this.baseCity
        })
        .then(response => {
          window.location.href = `/account/message?conversation=${response.data.data.id}`;
        });
    }
  }
};
</script>

<style scoped>
/* .trigger-chat {
  right: -200px !important;
} */
/* .footer-chat .box {
  visibility: visible !important;
  display: none;
}
.footer-chat.open-box .box {
  display: block !important;
} */
.chat {
  padding-bottom: 0px !important;
}
.ps {
  height: 400px !important;
}
</style>
