export default {
  header: {
    AboutUs: "Tentang Kami",
    FindProperties: "Cari Properti",
    MarketMyProperties: "Pasarkan Properti",
    OtherServices: "Jasa Lainnya",
    ConstructionService: "Jasa Konstruksi",
    MortgageService: "Jasa Kredit",
    Event: "Acara",
    ContactUs: "Hubungi Kami",
    LogReg: "Masuk/Daftar"
  },
  footer: {
    AboutUs: "Tentang Kami",
    FindProperties: "Cari Properti",
    MarketMyProperties: "Pasarkan Properti",
    OtherServices: "Jasa Lainnya",
    Event: "Acara",
    ContactUs: "Hubungi Kami",
    FAQ: "Tanya Jawab",
    TermCondition: "Syarat dan Ketentuan",
    PrivacyPolicy: "Kebijakan Privasi"
  },
  auth: {
    login: {
      header: "Selamat Datang Kembali",
      Title: "Masuk",
      ForgotPassword: "Lupa password Anda",
      RememberMe: "Ingat saya",
      Button: "Masuk"
    },
    register: {
      header: "Hai! Salam Kenal!",
      Title: "Daftar",
      FirstName: "Nama Depan",
      LastName: "Nama Belakang",
      MobileNumber: "Nomor HP",
      AcceptTC:
        "Terima <a href='/term-and-condition'>Syarat & Ketentuan</a> Dari Rubii",
      Button: "Daftar"
    }
  },
  home: {
    section1: {
      Tab1: "Sewa",
      Tab2: "Rental-untuk-Beli",
      Tab3: "Beli",
      Button: "Cari",
      PropertyType: "Jenis Properti",
      Bedrooms: "Jumlah Kamar Tidur",
      FurnishType: "Tipe Furnish",
      RentPeriod: "Periode Sewa",
      MaxPrice: "Harga Maximum",
      PlaceHolder: "Alamat, Kota, Daerah, Nama Proyek"
    },
    section2: {
      Button1: "Cari Properti",
      Button2: "Pelajari Lebih Lanjut"
    },
    section3: {
      Button1: "Kontak Kami",
      Button2: "Kenapa Rubii"
    },
    section4: {
      Title1: "Rekomendasi Rental-untuk-Beli",
      Title2: "Rekomendasi Sewa",
      Title3: "Rekomendasi Beli",
      Button: "Lihat Lainnya",
      Link: "Lihat Semua"
    },
    section5: {
      Button: "Pasarkan Properti"
    }
  },
  aboutUs: {
    Subtitle:
      "Jelajahi siapa kami sebenarnya dan bagaimana kami dapat membantu Anda",
    LatestArticle: "Artikel Terbaru",
    FeaturedArticle: "Artikel Unggulan",
    Share: "Bagikan",
    Article: "Artikel",
    DetailArticle: "Baca Selengkapnya",
    SeeDetails: "Lihat Selengkapnya",
    Vision: "Visi",
    Mission: "Misi",
    HowDoesItWork: "Bagaimana Cara Kerjanya?",
    InfoAndExampleRTO: "Informasi dan Contoh Mengenai Rental-untuk-Beli"
  },
  findProperty: {
    Title: "Cari Properti",
    Subtitle: "Temukan properti sempurna Anda hanya dengan beberapa klik saja.",
    FindAll: {
      Title1: "Rekomendasi Rental-untuk-Beli",
      Title2: "Rekomendasi Sewa",
      Title3: "Rekomendasi Beli",
      Button: "Lihat Lainnya",
      Link: "Lihat Semua"
    },
    FormSearch: {
      TabAll: "Semua",
      TabRent: "Sewa",
      TabRTO: "Rental-untuk-Beli",
      TabBuy: "Beli",
      BtnSearch: "Cari Sekarang",
      Placeholder: "Alamat, Kota, Daerah, Nama Proyek",
      PropertyType: "Jenis Properti",
      Bedrooms: "Jumlah Kamar Tidur",
      Bathrooms: "Jumlah Kamar Mandi",
      CarLot: "Jumlah Parkiran Mobil",
      MonthlyRentPrice: "Harga Sewa Bulanan",
      SalesPrice: "Harga Jual",
      FurnishType: "Tipe Furnish",
      RentPeriod: "Periode Sewa",
      LandSize: "Luas Tanah",
      BuildingSize: "Luas Bangunan",
      AdditionalFilterButton: "Filter Lainya",
      AdditionalFilterTitle: "Filter Tambahan"
    },
    Sorting: {
      HPrice: "Harga Tertinggi",
      LPrice: "Harga Terendah",
      NL: "Properti Terbaru"
    },
    PopUpFilter: {
      Province: "Provinsi",
      City: "Kota/kabupaten",
      Area: "Daerah",
      ZipCode: "Kode Pos",
      LandSize: "Luas Tanah",
      BuildingSize: "Luas Bangunan",
      Btn: "Cari Properti"
    },
    Information:
      "Tidak menemukan properti yang Anda inginkan? Yuk <a href='/request-property'>request properti</a> bersama kami.",
    Month: "Bulan",
    DetailProperty: {
      Title: "Temukan Properti",
      SubTitle: "Informasi Properti",
      PropertyDetails: "Informasi properti",
      LandSize: "Luas Tanah",
      BuildingSize: "Luas Bangunan",
      Bedrooms: "Kamar Tidur",
      Bathrooms: "Kamar Mandi",
      Water: "Sumber Air",
      CarLots: "Parkiran Mobil",
      LegalCertificate: "Legalitas",
      NearestLocations: "Lokasi Terdekat",
      ElementarySchool: "Sekolah Dasar (SD)",
      MiddleSchool: "Sekolah Menengah Pertama (SMP)",
      HighSchool: "Sekolah Menengah Atas (SMA)",
      University: "Universitas",
      ShoppingCentre: "Mall",
      Hospital: "Rumah Sakit",
      HighwayEnterance: "Pintu Masuk Tol",
      Fee: "Biaya",
      MaintenanceFee: "Maintenance Fee",
      NeighborhoodFee: "Iuran Lingkungan",
      OtherFee: "Biaya Lain-lain",
      Map: "Peta",
      OpenHouseEvent: "Acara Open House",
      SimilarProperty1: "Properti Serupa Yang Disewakan",
      SimilarProperty2: "Properti Serupa Yang Di Rental-untuk-Beli",
      SimilarProperty3: "Properti Serupa Yang Dijual",
      TransactionType: "Tipe Transaksi",
      RentPeriod: "Masa Sewa",
      PaymentOption: "Cara Pembayaran",
      MakeAnOffer: "Nego Harga",
      BookNow: "Pesan",
      Availability: "Properti Ini Dapat Anda Lihat!",
      PersonTour: "Lihat Ke Lokasi",
      VideoTour: "Melalui Video Call",
      NotInclude: "Apa yang tidak termasuk?",
      WhyDifferentPrice:
        "Mengapa harga berbeda tergantung pada opsi pembayaran?",
      ShowDetail: "Tampilkan detail",
      DifferentPriceDesc:
        "Semua harga untuk sewa dan rental-untuk-beli adalah dengan asumsi cara pembayaran yang umum yaitu Sekali Di Depan. Beberapa properti memperkenankan cara bayar yang lebih fleksibel tapi dengan dikenakan premium dari cara pembayaran Sekali Di Depan"
    }
  },
  marketMyProperties: {
    section1: {
      title: "Pasarkan Properti Saya",
      body:
        "Rubii bekerja sama dengan banyak pemilik properti dengan beragam jenis dan ukuran properti untuk menyediakan solusi yang nyaman, terpercaya dan transparan dalam memasarkan properti mereka."
    },
    section2: {
      title: "Mengapa pasarkan properti (listing) dengan kami?",
      body: `<p>
          Bagi Anda yang ingin menyewakan, menjual atau menawarkan dengan skema rental-untuk-beli properti Anda
          secara cepat dan nyaman, maka Rubii adalah pilihan tepat untuk Anda! Listing Anda akan juga GRATIS,
          tidak akan ada biaya proses, administrasi atau registrasi yang kami bebankan atau minta di awal.
          Kami hanya mengambil komisi sebagaimana dinyatakan secara transparan <a href="/about-us/commissions">disini</a>
          setelah properti Anda berhasil tersewakan/terjual/ter-rental-untuk-beli tanpa ada biaya tersembunyi apapun.
      </p>
      <p>
        Selain itu, memasarkan properti bersama Rubii juga Anda dapat lakukan dengan sangat mudah dan nyaman.
        Kami akan membantu memverifikasi properti Anda, mengambil foto dan video yang mewakili value properti Anda,
        dan memandu Anda dalam setiap langkah proses sambil memastikan kepatuhan hukum.
      </p>
      <p>
        Kami berkeyakinan bahwa menyewakan atau menjual sebuah properti seharusnya bukan hal yang berkepanjangan
        dan direpotkan dengan segala masalah, perincian, detail kecil, dihubungi oleh pihak yang tidak berkepentingan
        dan pusing dalam pematuhan proses hukum. Biarkan kami melakukan dan membereskan segala ini untuk Anda.
      </p>`
    },
    section3: {
      title: "Bagaimana Prosesnya?",
      one:
        "Isi kuesioner di atas ini atau hubungi salah satu Listing Officer kami.",
      two:
        "Salah satu Listing Officer kami akan mengkonfirmasi dan membuat janji dengan Anda untuk mengunjungi properti dan menandatangankan dokumen agar kami dapat memasarkan properti Anda.",
      three:
        "Kami akan memverifikasi properti dan mengambil foto dan video yang benar-benar mewakili nilai properti Anda. Atas kesepakatan, kami dapat memperindah properti Anda.",
      four:
        "Kami akan mengumpulkan semua data dan detail properti serta lingkungan sekitarnya dengan informasi dan/atau konfirmasi dari Anda.",
      five:
        "Properti Anda dengan semua dokumentasi dan informasi yang relevan akan diunggah ke platform kami. Ribuan pengguna kami sekarang dapat melihat properti Anda!",
      six:
        "Kami akan membantu untuk mempresentasikan ke calon penyewa/pembeli, menjadwalkan open house dan melakukan inspeksi properti Anda.",
      seven:
        "Saat ada penawaran atau negosiasi yang relevan, kami akan memberi tahu Anda! Jika Anda bersedia menyetujui penawaran atau ingin melakukan negosiasi balik, Anda dapat melakukan itu semua menggunakan situs web, aplikasi, dan/atau melalui Listing Officer kami.",
      eight:
        "Jika kesepakatan telah dibuat, Selamat! Listing Officer kami akan memberi tahu Anda."
    },
    desiredTransactions: "Transaksi yang diinginkan",
    chooseTransactions: "Anda dapat dipilih lebih dari satu",
    propertyType: "Jenis Properti",
    region: "Daerah",
    city: "Kota",
    postalCode: "Kode Pos",
    address: "Alamat",
    desiredRental: "Harga Sewa Yang Diinginkan <small>(jika berlaku)</small>",
    desiredSales: "Harga Jual Yang Diinginkan <small>(Jika berlaku)</small>",
    landSize: "Luas Tanah",
    buildingSize: "Luas Bangunan",
    numBedroom: "Jumlah Kamar Tidur",
    numBathroom: "Jumlah Kamar Mandi",
    numCarLot: "Jumlah Slot Parkir",
    waterSource: "Sumber Air",
    electricSource: "Sumber Listrik",
    wattage: "Watt Listrik",
    maintenanceFee: "Maintenance Fees",
    neighbourhoodFee: "Iuran Bulanan",
    month: "Bulan",
    year: "Tahun",
    description: "Deskripsi",
    contactLO: "Hubungi Listing Officer Kami"
  },
  event: {
    AllType: "Semua Tipe",
    AllLocation: "Semua Lokasi",
    Date: "Tanggal",
    Book: "Pesan Acara"
  },
  contactUs: {
    Name: "Nama",
    Placeholder: "Tulis pesan Anda",
    Submit: "Kirim",
    Message: "Pesan",
    Title: "Hubungi Kami",
    Header: "Ada Pertanyaan atau Masukan",
    SubHeader: "Kirim pesan Anda dan akan kami hubungi dalam 1 hari kerja."
  },
  dashboard: {
    Account: {
      Section1: {
        Title: "Informasi"
      },
      Section2: {
        Title: "Ganti Password"
      },
      Section3: {
        Title: "Aturan Notifikasi"
      },
      Section4: {
        Title: "Hapus Akun"
      }
    }
  },
  faq: {
    Title: "Pertanyaan yang Sering Diajukan",
    Button: "Cari",
    Placeholder: "Cari Pertanyaan",
    Categories: "Kategori",
    Articles: "Artikel"
  },
  forgotPassword: {
    TitleForgot: "Lupa kata sandi Anda?",
    SubtitleForgot:
      "Masukkan alamat email Anda dan kami akan mengirimkan instruksi untuk <br> mereset kata sandi Anda",
    BtnForgot1: "Kirim",
    BtnForgot2: "Kirim",
    TitleForgotOTP: "OTP Reset Password",
    SubtitleForgotOTP:
      "Silakan periksa kotak masuk Anda untuk memvalidasi OTP reset password Anda.",
    TitleResetPassword: "Buat Kata Password yang Kuat",
    SubtitleResetPassword: `Kata sandi Anda harus mengandung setidaknya 8 karakter, <br> 1 huruf kapital dan 1 angka.`
  }
};
