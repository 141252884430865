<template>
  <div id="login-register" class="login-active" style="display: none;">
    <button class="btn-cancel" style="visibility: hidden;" ref="closePopUp">
      Close
    </button>
    <div class="holder">
      <h2 class="for-login">
        {{ $t("auth.login.header") }}
      </h2>
      <h2 class="for-regis">
        {{ $t("auth.register.header") }}
      </h2>
      <div class="navtab">
        <a href="#tab-login" ref="loginTab" class="for-login active">
          {{ $t("auth.login.Title") }}
        </a>
        <a href="#tab-regis" ref="registerTab" class="for-regis">
          {{ $t("auth.register.Title") }}
        </a>
      </div>
      <!-- end of nav tab -->

      <div id="tab-login" class="item active">
        <div class="form-basic">
          <form @submit.prevent="login">
            <div class="fgroup">
              <label>Email</label>
              <input
                type="email"
                name="email"
                v-model="signIn.email"
                required
              />
            </div>
            <!-- end of fgroup -->
            <div class="fgroup">
              <label>Password</label>
              <div class="pass-field">
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  v-model="signIn.password"
                  required
                />
                <b></b>
              </div>
              <!-- end of pass field -->
              <div class="fgroup" style="margin-top: 15px">
                <div class="checky" style="text-align: center; color: red">
                  <p v-for="(error, index) in signIn.errorMessage" :key="index">
                    {{ error }}
                  </p>
                </div>
              </div>
              <!-- end of group -->
              <a
                href="javascript:"
                data-src="#forgot-pass"
                class="forgot"
                data-fancybox
              >
                {{ $t("auth.login.ForgotPassword") }}?
              </a>
            </div>
            <!-- end of fgroup -->
            <div class="fgroup">
              <div class="checky">
                <label>
                  <input type="checkbox" v-model="signIn.rememberMe" />
                  <span>{{ $t("auth.login.RememberMe") }}</span>
                </label>
              </div>
              <button type="submit" class="button">
                {{ $t("auth.login.Button") }}
              </button>
            </div>
            <!-- end of group -->
          </form>
        </div>
        <!-- end of form basic -->
      </div>
      <!-- end of item -->

      <div id="tab-regis" class="item">
        <div class="form-basic">
          <form @submit.prevent="register">
            <div class="gap">
              <div class="fgroup">
                <label>{{ $t("auth.register.FirstName") }}</label>
                <input type="text" v-model="signUp.firstName" required />
              </div>
              <!-- end of frgroup -->
              <div class="fgroup">
                <label>{{ $t("auth.register.LastName") }}</label>
                <input type="text" v-model="signUp.lastName" required />
              </div>
              <!-- end of frgroup -->
            </div>
            <!-- end of gap -->
            <div class="fgroup">
              <label>Email</label>
              <input type="email" v-model="signUp.email" required />
            </div>
            <!-- end of fgroup -->
            <div class="fgroup">
              <label>{{ $t("auth.register.MobileNumber") }}</label>
              <input type="tel" v-model="signUp.mobileNumber" required />
            </div>
            <!-- end of fgroup -->
            <div class="fgroup">
              <label>Password</label>
              <div class="pass-field">
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  v-model="signUp.password"
                  required
                />
                <b></b>
              </div>
              <!-- end of pass field -->
            </div>
            <div class="fgroup">
              <div class="checky" style="text-align: center; color: red">
                <p v-for="(error, index) in signUp.errorMessage" :key="index">
                  {{ error }}
                </p>
              </div>
            </div>
            <!-- end of group -->
            <!-- end of fgroup -->
            <div class="fgroup">
              <div class="checky">
                <label>
                  <input type="checkbox" v-model="signUp.termConditions" />
                  <span v-html="$t('auth.register.AcceptTC')"></span>
                </label>
              </div>
            </div>
            <!-- end of group -->
            <div class="fgroup">
              <button
                type="submit"
                class="button"
                :disabled="!signUp.termConditions"
              >
                {{ $t("auth.register.Button") }}
              </button>
            </div>
            <!-- end of group -->
          </form>
        </div>
        <!-- end of form basic -->
      </div>
      <!-- end of item -->
    </div>
    <!-- end of holder -->
  </div>
  <!-- end of popup -->
</template>

<script>
import $ from "jquery";
import cookie from "js-cookie";

export default {
  name: "LoginRegister",
  data() {
    return {
      signIn: {
        email: null,
        password: null,
        rememberMe: false,
        errorMessage: []
      },
      signUp: {
        firstName: null,
        lastName: null,
        email: null,
        mobileNumber: null,
        password: null,
        termConditions: false,
        errorMessage: []
      }
    };
  },
  mounted() {
    $("#login-register .btn-cancel").on("click", function(e) {
      e.preventDefault();
      $(this)
        .closest("#login-register ")
        .find(".fancybox-close-small")
        .trigger("click");
    });
  },
  methods: {
    login() {
      this.signIn.errorMessage = [];
      this.$axios
        .post(`/auth/login`, {
          email: this.signIn.email,
          password: this.signIn.password,
          isAdmin: false
        })
        .then(response => {
          let userdata = response.data.data;
          if (userdata.account.user === undefined) {
            userdata.account.user = userdata.account.admin;
          }
          this.$store.commit("SET_USERDATA", userdata);
          cookie.set("rubii", userdata, { expires: 1 });
          setTimeout(() => {
            window.location.reload();
          }, 500);
        })
        .catch(error => {
          if (error.response.data.code == 422) {
            this.signIn.errorMessage = error.response.data.data.map(
              error => error.message
            );
          } else if (error.response.data.code == 400) {
            this.signIn.errorMessage.push(error.response.data.message);
          }
        });
    },
    register() {
      this.signUp.errorMessage = [];
      this.$axios
        .post(`/auth/register`, {
          firstName: this.signUp.firstName,
          lastName: this.signUp.lastName,
          email: this.signUp.email,
          mobileNumber: this.signUp.mobileNumber,
          password: this.signUp.password
        })
        .then(() => {
          this.$swal({
            icon: "success",
            title: "registration successful",
            text: "please check your email to verify your account"
          });
          this.$refs.closePopUp.click();
        })
        .catch(error => {
          if (error.response.data.code == 422) {
            this.signUp.errorMessage = error.response.data.data.map(
              error => error.message
            );
          } else if (error.response.data.code == 400) {
            this.signUp.errorMessage.push(error.response.data.message);
          }
        });
    }
  }
};
</script>

<style scoped>
button:disabled {
  cursor: not-allowed;
  background-color: grey;
  color: #093763;
}
</style>
