export default {
  header: {
    AboutUs: "About Us",
    FindProperties: "Find Properties",
    MarketMyProperties: "Market My Properties",
    OtherServices: "Other Services",
    ConstructionService: "Construction Service",
    MortgageService: "Mortgage Service",
    Event: "Events",
    ContactUs: "Contact Us",
    LogReg: "Log In / Sign Up"
  },
  footer: {
    AboutUs: "About Us",
    FindProperties: "Find Properties",
    MarketMyProperties: "Market My Properties",
    OtherServices: "Other Services",
    Event: "Event",
    ContactUs: "Contact Us",
    FAQ: "FAQ",
    TermCondition: "Terms & Conditions",
    PrivacyPolicy: "Privacy Policy"
  },
  auth: {
    login: {
      header: "Welcome Back",
      Title: "Login",
      ForgotPassword: "Forgot your password",
      RememberMe: "Remember Me",
      Button: "Login"
    },
    register: {
      header: "Hey There!",
      Title: "Sign Up",
      FirstName: "First Name",
      LastName: "Last Name",
      MobileNumber: "Mobile Number",
      AcceptTC:
        "Accept the <a href='/term-and-condition'>Terms & Conditions</a> from Rubii",
      Button: "Register"
    }
  },
  home: {
    section1: {
      Tab1: "Rent",
      Tab2: "Rent-to-Own",
      Tab3: "Buy",
      Button: "Search Now",
      PropertyType: "Property Type",
      Bedrooms: "Bedrooms",
      FurnishType: "Furnish Type",
      RentPeriod: "Rent Period",
      MaxPrice: "Max Price",
      PlaceHolder: "Address, City, Region, Project Name"
    },
    section2: {
      Button1: "Find Properties",
      Button2: "Learn More"
    },
    section3: {
      Button1: "Contact Us",
      Button2: "Why Rubii"
    },
    section4: {
      Title1: "Quick Rent-to-Own",
      Title2: "Quick Rent",
      Title3: "Quick Buy",
      Button: "Browse More",
      Link: "See All"
    },
    section5: {
      Button: "Market My Properties"
    }
  },
  aboutUs: {
    Subtitle: "Explore who we really are and how we can help you",
    LatestArticle: "Latest Articles",
    FeaturedArticle: "Featured Articles",
    Share: "Share",
    Article: "Articles",
    DetailArticle: "Read More",
    SeeDetails: "See Details",
    Vision: "Vision",
    Mission: "Mission",
    HowDoesItWork: "How does it work?",
    InfoAndExampleRTO: "Information & Example Pertaining to Rent-to-Own"
  },
  findProperty: {
    Title: "Find Properties",
    Subtitle: "Find your perfect property just a few clicks away.",
    FindAll: {
      Title1: "Quick Rent-to-Own",
      Title2: "Quick Rent",
      Title3: "Quick Buy",
      Button: "Browse More",
      Link: "See All"
    },
    FormSearch: {
      TabAll: "All",
      TabRent: "Rent",
      TabRTO: "Rent-to-Own",
      TabBuy: "Buy",
      BtnSearch: "Search Now",
      Placeholder: "Address, City, Region, Project Name",
      PropertyType: "Property Type",
      Bedrooms: "Bedrooms",
      Bathrooms: "Bathrooms",
      CarLot: "Car Lots",
      MonthlyRentPrice: "Monthly Rent Price",
      SalesPrice: "Sales Price",
      FurnishType: "Furnish Type",
      RentPeriod: "Rent Period",
      LandSize: "Land Size",
      BuildingSize: "Building Size",
      AdditionalFilterButton: "Add. Filters",
      AdditionalFilterTitle: "Additional Filters"
    },
    Sorting: {
      HPrice: "Highest Price",
      LPrice: "Lowest Price",
      NL: "Newest Listing"
    },
    PopUpFilter: {
      Province: "Province",
      City: "City",
      Area: "Area",
      ZipCode: "Zip Code",
      LandSize: "Land Size",
      BuildingSize: "Building Size",
      Btn: "Find Property"
    },
    Information:
      "Cannot find the property that you have in mind? Let us know and <a href='/account/request-property'>request a property</a> with us",
    Month: "Month",
    DetailProperty: {
      Title: "Find Properties",
      SubTitle: "Property Details",
      PropertyDetails: "Property details",
      LandSize: "Land Size",
      BuildingSize: "Building Size",
      Bedrooms: "Bedrooms",
      Bathrooms: "Bathrooms",
      Water: "Water",
      CarLots: "Car Lots",
      LegalCertificate: "Legal Certificate",
      NearestLocations: "Nearest Locations",
      ElementarySchool: "Elementary School",
      MiddleSchool: "Middle School",
      HighSchool: "High School",
      University: "University",
      ShoppingCentre: "Shopping Centre",
      Hospital: "Hospital",
      HighwayEnterance: "Highway Enterance",
      Fee: "Fees",
      MaintenanceFee: "Maintenance Fees",
      NeighborhoodFee: "Neighborhood Fees",
      OtherFee: "Other Fees",
      Map: "Map",
      OpenHouseEvent: "Open House Event",
      SimilarProperty1: "Similar Properties For Rent",
      SimilarProperty2: "Similar Properties For Rent-to-Own",
      SimilarProperty3: "Similar Properties For Buy",
      TransactionType: "Transaction Type",
      RentPeriod: "Rent Period",
      PaymentOption: "Payment Option",
      MakeAnOffer: "Make an Offer",
      BookNow: "Book Now",
      Availability: "This Property is Available for Tour!",
      PersonTour: "In-Person Tour",
      VideoTour: "Video Call Tour",
      NotInclude: "What is not included?",
      WhyDifferentPrice: "Why do prices differ depending on payment options? ",
      ShowDetail: "Show Detail",
      DifferentPriceDesc:
        "All stated prices for rent and rent-to-own are assuming the standard Upfront payment method. Some properties enable more flexible payment methods but they are subject to premiums from the Upfront payment method"
    }
  },
  marketMyProperties: {
    section1: {
      title: "Market Your Property With Us!",
      body:
        "Rubii works with countless property owners of all types and sizes to provide a convenient, reliable and transparent solution to market properties."
    },
    section2: {
      title: "Why list with us?",
      body: `<p>
        If you are looking to rent out, sell or rent-to-own out your
        property, you have come to the right place! And unless we make
        your desired transactions happen, it will be completely FREE!
        There will be no processing fee, no administration fee, no
        platform fee. We only take a commission as transparently stated
        <a href="/about-us/commissions">here</a> with no
        hidden charges.
      </p>
      <p>
        In addition, you can do it so conveniently. We will help to verify
        your property, take photos and videos that represent your
        property’s value and guide you in every step of the process while
        ensuring legal compliance.
      </p>
      <p>
        We believe that looking to rent or sell your properties should not
        take ages and be consumed with all the hassles, troubles, small
        details, unwanted approaches and legal attention. Let us do it for
        you conveniently.
      </p>`
    },
    section3: {
      title: "What are the processes?",
      one:
        "Fill up the questionnaire below or contact one of our listing officers.",
      two:
        "Upon confirmation, one of our listing officers will set up an appointment with you to visit the property and sign paperworks that will allow us to market your property.",
      three:
        "We will verify the property and take photos and videos that truly represent your property’s value. Staging may beconducted upon approval.",
      four:
        "We will gather data of all the property’s details and surroundings with your information and/or confirmation.",
      five:
        "Your property with all the relevant documentations and information will be uploaded into our platform. Thousands of our users will now be able to view your property!",
      six:
        "We will help to conduct viewings, open houses and inspections on your behalf.",
      seven:
        "Once there is a suitable offer or negotiation, we will let you know! Whether you agree or would like to counter-negotiate you can do that all using our website, app and/or through our listing officer.",
      eight:
        "If a deal has been made, Congratulations! Our listing officer will inform you accordingly."
    },
    desiredTransactions: "Desired transactions",
    chooseTransactions: "you can choose more than one",
    propertyType: "Property Type",
    region: "Region",
    city: "City",
    postalCode: "Postal Code",
    address: "Address",
    desiredRental: "Desired Rental Rate <small>(if applicable)</small>",
    desiredSales: "Desired Sales Price <small>(if applicable)</small>",
    landSize: "Land Size",
    buildingSize: "Building Size",
    numBedroom: "Number of Bedrooms",
    numBathroom: "Number of Bathrooms",
    numCarLot: "Number of Car Lots",
    waterSource: "Water Source",
    electricSource: "Electric Source",
    wattage: "Wattage",
    maintenanceFee: "Maintenance Fees",
    neighbourhoodFee: "Neighbourhood Fees",
    month: "Month",
    year: "Year",
    description: "Description",
    contactLO: "Contact Our Listing Officer"
  },
  event: {
    AllType: "All Type",
    AllLocation: "All Location",
    Date: "Date",
    Book: "Book Event"
  },
  contactUs: {
    Name: "Name",
    Placeholder: "Write your message",
    Submit: "Submit",
    Message: "Message",
    Title: "Get In Touch",
    Header: "Have Any Questions or Feedback",
    SubHeader:
      "Send us a message and we will be in touch <br />within one business day."
  },
  dashboard: {
    Account: {
      Section1: {
        Title: "Details"
      },
      Section2: {
        Title: "Change Password"
      },
      Section3: {
        Title: "Notification Settings"
      },
      Section4: {
        Title: "Delete Account"
      }
    }
  },
  faq: {
    Title: "Frequently Asked Questions",
    Button: "Search Now",
    Placeholder: "Search for a question",
    Categories: "Categories",
    Articles: "Articles"
  },
  forgotPassword: {
    TitleForgot: "Forgot Your Password?",
    SubtitleForgot:
      "Enter your email address and we will send you the instructions to reset your password",
    BtnForgot1: "Send",
    BtnForgot2: "Submit",
    TitleForgotOTP: "Reset Password OTP",
    SubtitleForgotOTP:
      "Please check your inbox to validate your reset password OTP.",
    TitleResetPassword: "Create A Strong Password",
    SubtitleResetPassword: `Your password must contain at least 8 characters, <br> 1 capital letter and 1 number`
  }
};
