<template>
  <div id="forgot-pass" style="display: none;">
    <div class="holder" v-if="step === 1">
      <h2>{{ $t("forgotPassword.TitleForgot") }}</h2>
      <div class="txt">
        <p v-html="$t('forgotPassword.SubtitleForgot')"></p>
      </div>
      <!-- end of txt -->
      <div class="form-basic">
        <form @submit.prevent="requestOtp">
          <div class="fgroup has-mail">
            <b class="cvr-bg"></b>
            <input
              type="email"
              v-model="email"
              placeholder="your email here.."
              required
            />
          </div>
          <!-- end of fgroup -->
          <div class="fgroup">
            <div class="checky" style="text-align: center; color: red">
              <p v-for="(error, index) in errorMessageForgot" :key="index">
                {{ error }}
              </p>
            </div>
          </div>
          <div class="fgroup">
            <br />
            <button type="submit" class="button">
              {{ $t("forgotPassword.BtnForgot1") }}
            </button>
          </div>
          <!-- end of group -->
        </form>
      </div>
      <!-- end of form basic -->
    </div>
    <!-- end of holder -->
    <div class="holder" v-if="step === 2">
      <h2>{{ $t("forgotPassword.TitleForgotOTP") }}</h2>
      <div class="txt">
        <p>
          {{ $t("forgotPassword.SubtitleForgotOTP") }}
        </p>
      </div>
      <!-- end of txt -->
      <div class="form-basic">
        <form @submit.prevent="verifyOtp">
          <div class="fgroup">
            <b class="cvr-bg"></b>
            <input
              type="number"
              v-model="otp"
              placeholder="Input OTP here"
              required
            />
          </div>
          <!-- end of fgroup -->
          <div class="fgroup">
            <div class="checky" style="text-align: center; color: red">
              <p v-for="(error, index) in errorMessageOtp" :key="index">
                {{ error }}
              </p>
            </div>
          </div>
          <div class="fgroup">
            <br />
            <button type="submit" class="button">
              {{ $t("forgotPassword.BtnForgot2") }}
            </button>
          </div>
          <!-- end of group -->
        </form>
      </div>
      <!-- end of form basic -->
    </div>
    <!-- end of holder -->
    <button ref="closeModal" class="button close" style="visibility: hidden;">
      Close
    </button>
  </div>
  <!-- end of popup -->
</template>

<script>
import $ from "jquery";

export default {
  name: "ForgotPassword",
  data() {
    return {
      email: null,
      otp: null,
      otpToken: null,
      errorMessageForgot: [],
      errorMessageOtp: [],
      step: 1
    };
  },
  mounted() {
    $("#forgot-pass .button.close").on("click", function(e) {
      e.preventDefault();
      $(this)
        .closest("#forgot-pass")
        .find(".fancybox-close-small")
        .trigger("click");
    });
  },
  methods: {
    requestOtp() {
      this.errorMessageForgot = [];
      this.$axios
        .post(`/auth/request-otp`, {
          email: this.email
        })
        .then(response => {
          console.log(response.data);
          this.step = 2;
          this.otpToken = response.data.data.otpToken;
        })
        .catch(error => {
          if (error.response.data.code == 422) {
            this.errorMessageForgot = error.response.data.data.map(
              error => error.message
            );
          } else if (
            error.response.data.code == 400 ||
            error.response.data.code == 404
          ) {
            this.errorMessageForgot.push(error.response.data.message);
          }
        });
    },
    verifyOtp() {
      this.errorMessageOtp = [];
      this.$axios
        .post(`/auth/verify-otp`, {
          otp: this.otp,
          otpToken: this.otpToken
        })
        .then(response => {
          console.log(response.data);
          this.$router.push(
            `/reset-password/${response.data.data.validOtpToken}`
          );
          this.$refs.closeModal.click();
        })
        .catch(error => {
          if (error.response.data.code == 422) {
            this.errorMessageOtp = error.response.data.data.map(
              error => error.message
            );
          } else if (
            error.response.data.code == 400 ||
            error.response.data.code == 404
          ) {
            this.errorMessageOtp.push(error.response.data.message);
          }
        });
    }
  }
};
</script>
